<template>
  <!-- 简档主页 -->
  <div class="body">
    <Header-card
      :selectTitleName="rolePageDropDownName"
      :HomePageListData="HomePageListData"
      :rolePageDropDownName="rolePageDropDownName"
      :rolePageDropDownNameS="rolePageDropDownNameS"
      :isAdmin="true"
      :isCoustomPageToRolePage="isCoustomPageToRolePage"
      :isEditStatus="isEditStatus"
      @rolePageHeaderCardExitEvent="rolePageHeaderCardExitEvent"
      @rolePageHeaderCardDeleteEvent="rolePageHeaderCardDeleteEvent"
      @rolePageHeaderCardReSortEvent="rolePageHeaderCardReSortEvent"
      @rolePageHeaderCardAddCpnEvent="rolePageHeaderCardAddCpnEvent"
      :rolePageObjectData="homePageObjectData"
      @HeaderBarEditRolePageNameEvent="HeaderBarEditRolePageNameEvent"
      @cancelSwitchs="cancelEditStatus"
      @saveRolePageHeaderEditPageNameFormEvent="saveRolePageNameEvent"
      @refreshEvent="refreshEvent"
      @ComponentsDelConfirmRol="rolePageHeaderCardDeleteEvent"
      @saveEvent="savePageLayout"
      @screenFull="screenFull"
    />
    <!-- 内容区域 -->
    <Common-index
      class="common_index_div"
      useLocation="homePage"
      :commonDataArr="commonDataArr"
      :isEditStatus="isEditStatus"
      :homePageObject="homePageObjectData"
      @locationLayout="locationLayout"
      ref="CommonIndex"
    />
    <!-- 添加（编辑）组件弹框 -->
    <ComPersonAddAssembly
      :isShowComPersonAddAssembly="isShowComPersonAddAssembly"
      :homePageObjectData="homePageObjectData"
      @assemblyCancel="assemblyCancel"
      @assemblySave="assemblySave"
      @cardCpnEditSave="cardCpnEditSave"
      @fixedComponentdDataSave="fixedComponentdDataSave"
      @saveDashboardCpn="saveDashboardCpn"
      @assemblyDashboardEditSave="assemblyDashboardEditSave"
      @editPinnedComponents="editPinnedComponents"
      @dashboardPageSave="dashboardPageSave"
      @instrumentClusterSave="instrumentClusterSave"
      @customComponentdEdit="customComponentdEdit"
      ref="ComPersonAddAssemblyRef"
    />

    <!-- 组件编辑器弹框
          :dashboardid="dashboardid"
      :watchLayoutArr="watchLayoutArr"
     -->
    <ComponentEditor
      :isShowComponentsEditorAlert="isShowComponentsEditorAlert"
      :isAddToHomePage="isAddToHomePage"
      ref="ComponentEditorRef"
      :dashboardy="dashboardy"
      :sourceType="sourceType"
      :isShowSetUpComponents="isShowSetUpComponents"
      :chartInfo="chartInfo"
      :linkageArr="linkageArr"
      @cancel="componentEditorCancel"
      @affirm="componentEditorAffirm"
    ></ComponentEditor>

    <!-- 添加个人主页删除组件弹框 -->
    <DialogDelect
      :nameCpn="nameCpn"
      :relatedidCpn="relatedidCpn"
      :dialogDelectTo="dialogDelectTo"
      @ComponentsDelCancle="ComponentsDelCancle"
      @ComponentsDelConfirm="ComponentsDelConfirm"
    />
    <!-- 图表全屏组件 -->
    <chartFullScreen
      :chartFullScreenTo="chartFullScreenTo"
      :DashboardDataFullScreen="DashboardDataFullScreen"
      @chartFullScreenClose="chartFullScreenClose"
      :dataShowName="dataShowName"
    />
    <!-- 主页内容全屏弹框 -->
    <homePageFullScreen
      v-if="homePageFullScreenTo"
      :homePageFullScreenTo="homePageFullScreenTo"
      @homePageFullScreenClose="homePageFullScreenClose"
      :homePagetitle="`${rolePageDropDownName}`"
    />
  </div>
</template>

<script>
import chartFullScreen from "@/components/FullScreen/chartFullScreen"; //图表全屏组件
import homePageFullScreen from "@/components/FullScreen/homePageFullScreen"; // 主页内容全屏组件
import HeaderCard from "../components/HeaderCard";
import CommonIndex from "../components/CommonIndex";
import * as request from "./api.js";
// import EmbeddedPage from "../components/EmbeddedPage"; //嵌入页面组件
import ComPersonAddAssembly from "../personalPage/components/ComPersonAddAssembly";
import getCpnLocation from "@/utils/getCpnLocation"; //获取当前底部组件的位置信息
import DialogDelect from "../components/DialogDelect";
import * as types from "@/store/mutations-types";

import ComponentEditor from "@/views/dashboardObject/dashboardIndex/components/ComponentEditor";
import mixin from "@/mixin/homepageAndDashboardMixin.js";

export default {
  components: {
    HeaderCard,
    CommonIndex,
    // EmbeddedPage,
    ComPersonAddAssembly,
    DialogDelect,
    chartFullScreen,
    homePageFullScreen,

    ComponentEditor,
  },
  mixins: [mixin],

  data() {
    return {
      isShowComponentsEditorAlert: false, //组件编辑器弹框
      isShowSetUpComponents: true, //设置组件选项
      sourceType: "", //生成图表数据来源
      isAddToHomePage: true, // 表示添加到仪表板还是主页，来控制仪表板id是否为空

      dataShowName: "",
      DashboardDataFullScreen: {},
      chartFullScreenTo: false,
      homePageFullScreenTo: false, //主页内容是否全屏
      isEditStatus: false,
      isCoustomPageToRolePage: false, //自定义主页到简档主页
      rolePageDropDownName: "", //简档主页的名称
      rolePageDropDownNameS: "", //标准主页的名称
      HomePageListData: [], //全部主页对象列表
      /**
       * 当前页面的对象(包含name,id,type)
       * 当此页面时从自定义页面进来时(有id)给赋值
       */
      homePageObjectData: {}, //页面对象数据
      isShowComPersonAddAssembly: false, //是否显示添加组件弹框
      commonDataArr: [], //布局数组
      idCpn: "", //组件id（编辑删除时使用）
      dialogDelectTo: false,
      nameCpn: "",
      relatedidCpn: "",
      locationLayoutVal: [], //当前重新排序后的布局对象
      /**
       * 点击卡片视图的编辑时保存当前的组件对象，编辑保存时传递位置使用
       * 编辑卡片视图和仪表板组件时都用此组件位置的对象进行调用接口的布局坐标保存
       */
      editCpnSelectCpnObject: {},
    };
  },
  created() {
    /**
     * 如果把简档主页设置为了默认主页，
     * 则页面加载进来时就会直接到简档主页，
     * 否则直接进入标准主页，
     * 每次执行完都会清空cookie中的跳转路径，以供下次判断使用
     * 在 简档主页中的 （rolePage）和 此判断(standardPage的请求中)的下面都有清空路径保存 （clickToPage） 事件
     */
    this.$cookies.set("clickToPage", "", { sameSite: 'Strict' });
    /**
     * 如果是从自定义主页进来的就请求此接口
     * 只有(管理员)从自定义主页进来的就展示可以重新排序菜单,否则显示正常人可以看到的菜单
     */

    this.$route.query.id && this.getHomePageLayout();
    this.$route.query.id
      ? (this.isCoustomPageToRolePage = true)
      : (this.isCoustomPageToRolePage = false);
    /**
     * 获取简档主页布局(从下拉进入的简档主页)
     */
    !this.$route.query.id && this.getHomePageLayoutData("profile");
  },
  mounted() {
    // 新增图表
    this.$bus.$on("showComponentsEditorAlert", this.ebFn1);
    this.$bus.$on("isShowComPersonAddAssemblyBus", this.ebFn2);
    //========================================
    //控制图表全屏展示
    this.$bus.$on("clickChartFullScreen", this.ebFn3);
    // 删除卡片视图组件方法
    this.$bus.$on("deleteCpn", this.ebFn4);
    // 编辑卡片视图组件方法（回显）
    this.$bus.$on("editCpn", this.ebFn5);

    // 删除固定组件的方法
    this.$bus.$on("deleteFixedComponents", this.ebFn6);
    // 编辑固定组件的方法
    this.$bus.$on("editPinned", this.ebFn7);

    //  编辑仪表板面板的方法
    this.$bus.$on("instrumentPanel", this.ebFn8);
    //删除仪表板组件的方法
    this.$bus.$on("fnchoosedelete", this.ebFn9);
    //编辑仪表板组件的方法（回显）
    this.$bus.$on("fnchooseEdit", this.ebFn10);
  },
  destroyed() {
    this.$bus.$off("showComponentsEditorAlert", this.ebFn1);
    this.$bus.$off("isShowComPersonAddAssemblyBus", this.ebFn2);
    this.$bus.$off("clickChartFullScreen", this.ebFn3);
    this.$bus.$off("deleteCpn", this.ebFn4);
    this.$bus.$off("editCpn", this.ebFn5);
    this.$bus.$off("deleteFixedComponents", this.ebFn6);
    this.$bus.$off("editPinned", this.ebFn7);
    this.$bus.$off("instrumentPanel", this.ebFn8);
    this.$bus.$off("fnchoosedelete", this.ebFn9);
    this.$bus.$off("fnchooseEdit", this.ebFn10);
  },
  methods: {
    /**
     * 获取报表视图中需要获取快照的图表列表
     * @param {Array} arr getHomePageLayout返回的数据
     * @returns 需要获取快照的图表列表
     */
    getLayoutArr(arr) {
      return arr.reduce((prev, next) => {
        if (next.layout && next.layout.length && next.layout.length > 0) {
          prev.push(...next.layout);
        } else if (next.i) {
          prev.push(next);
        }
        return prev;
      }, []);
    },
    ebFn1() {
      this.isShowComponentsEditorAlert = true;
      // 新增图表时清空数据
      this.chartInfo = {};
      this.homePageChartInfo = {};
    },
    ebFn2() {
      this.isShowComPersonAddAssembly = false;
    },
    ebFn3(e, dataShowName) {
      this.DashboardDataFullScreen = e;
      this.chartFullScreenTo = true;
      this.dataShowName = dataShowName;
    },
    ebFn4(val, name) {
      this.dialogDelectTo = true;
      this.idCpn = val;
      this.nameCpn = name;
      this.relatedidCpn = "";
    },
    ebFn5(val) {
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val.cpnObject;

      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
      // 是否是编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isEditToThis = true;

      // 卡片视图id
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.i = val.cpnObject.i;
      // 卡片视图名字
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardName =
        val.cpnName;
      //  卡片视图选择对象
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardObject =
        val.cpnObject.objid;
      //  卡片视图选择id
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardView =
        val.cpnObject.relatedid;
      //  选择展示字段数组
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardField =
        val.cpnObject.fieldids.split(",");
      //  选择按钮操作项
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardChoosebtn =
        val.cpnObject.CardChoosebtnIdArr;
      // 保存组件中传来的组件id，在当前页面保存，删除时使用
      this.idCpn = val.cpnId;
    },
    ebFn6(val, name, relatedid) {
      this.dialogDelectTo = true;
      this.idCpn = val;
      this.nameCpn = name;
      this.relatedidCpn = relatedid;
    },
    ebFn7(val) {
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val.cpnObject;
      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
      // 是否是编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isFixedComponents = true;
      // 固定组件Name fixedForm
      this.$refs.ComPersonAddAssemblyRef._data.fixedForm.fixedCpnName =
        val.cpnName;
      this.$refs.ComPersonAddAssemblyRef._data.fixedForm.fixedCpnId =
        val.cpnObject.relatedid;
      // 保存组件中传来的组件id，在当前页面保存，删除时使用
      this.idCpn = val.cpnId;
    },
    ebFn8(val) {
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val.cpnObject;
      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
      // 仪表板组件布局id
      this.$refs.ComPersonAddAssemblyRef._data.DashboardruleForm.i =
        val.cpnObject.i;
      // 是否为编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isDashboardPagEditing = true;
      // 仪表板面板名字
      this.$refs.ComPersonAddAssemblyRef._data.DashboardruleForm.DashboardName =
        val.cpnName;
      // 选择仪表板
      this.$refs.ComPersonAddAssemblyRef._data.DashboardruleForm.DashboardChooseID =
        val.cpnObject.dashboardid;
    },
    ebFn9(val, name) {
      //让删除组件弹框显示
      this.dialogDelectTo = true;
      this.idCpn = val;
      this.nameCpn = name;
      this.relatedidCpn = "";
    },
    ebFn10(val, val2, val3) {
      if (val.type === "homepageChart") {
        this.fnchooseEdit(val3, val);
        return;
      }
      this.$refs.ComPersonAddAssemblyRef._data.isDashboardEditToThis = true;

      // val是图表的数据，val2是组件的数据
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val2;

      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
      // 仪表板组件布局id
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.i = val2.i;
      // 仪表板组件名字
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.DashboardName =
        val2.name;
      // 选择仪表板
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.DashboardChooseID =
        val.dashboardid;
      // 选择组件（回显）
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.DashboardassemblyID =
        val.i;
      // 仪表板编辑时使用此id，保存时通过此变量发送到仪表板更新使用（即当前图表的i）
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.dashboardEditId =
        val.i;

      // 是否仪表板是编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isDashboardEditToThis = true;
    },
    //单个图表的全屏关闭
    chartFullScreenClose() {
      this.chartFullScreenTo = false;
    },
    //使简档主页内容区域全屏
    screenFull() {
      this.homePageFullScreenTo = true;
      setTimeout(() => {
        this.$bus.$emit("screenFullRoleData", this.commonDataArr);
      }, 0);
    },
    //关闭全屏弹框
    homePageFullScreenClose() {
      this.homePageFullScreenTo = false;
    },
    // 全局刷新
    refreshEvent() {
      this.$bus.$emit("refreshOverallData");
    },
    // 关闭组件删除弹框
    ComponentsDelCancle() {
      this.dialogDelectTo = false;
    },
    //简档主页组件删除
    async ComponentsDelConfirm() {
      let res = await request.deleteHomePageComponent({
        id: this.idCpn,
      });
      if (res.result) {
        // 删除组件成功
        this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
        this.dialogDelectTo = false;
        /**
         * 删除后---获取主页布局数据
         * （跟刚进来时判断一样，如果有 router id使用那个接口，没有id使用那个接口）
         */

        this.$route.query.id && (await this.getHomePageLayout());
        this.$route.query.id
          ? (this.isCoustomPageToRolePage = true)
          : (this.isCoustomPageToRolePage = false);
        /**
         * 获取简档主页布局(从下拉进入的简档主页)
         */
        !this.$route.query.id && this.getHomePageLayoutData("profile");
        //删除后---重新保存布局
        this.savePageLayout();
        // 在删除图表之后会触发组件的destroyed钩子，会把该组件的所有引用里的$bus.$on事件销毁，所以需要遍历该仪表板下剩下的所有图表，手动添加上监听事件
        if (this.$refs.CommonIndex.$refs.DashboardCard.length > 0) {
          this.$refs.CommonIndex.$refs.DashboardCard.map((item) => {
            item.getLinkagegetDataMethod();
          });
        }
      } else {
        this.$message.error(res.returnInfo);
      }
    },
    // 保存页面布局
    async savePageLayout() {
      let parmas = {
        homePageId: this.homePageObjectData.id,
        components: JSON.parse(JSON.stringify(this.locationLayoutVal)),
      };
      parmas.components.map((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      // 给后台的数据需要转成字符串传送
      parmas.components = JSON.stringify(parmas.components);
      await request.saveHomePageComponentsLayout(parmas);
      // 让重新排序状态为false
      this.isEditStatus = false;
      // this.$message.success("保存页面布局成功!");
      // 保存布局成功，重新请求布局
      this.getHomePageLayout();
    },
    // 页面重新排序，保存页面布局
    locationLayout(location) {
      this.locationLayoutVal = location;
    },
    // 如果是从自定义主页进来的就请求此接口,获取简档主页布局(有id)
    getHomePageLayout() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          let { data } = await request.getHomePageLayout({
            id: this.$route.query.id,
          });
          this.commonDataArr = data.data || [];
          // 页面的参数对象
          this.homePageObjectData = {
            homePageName: data.homePageName,
            id: this.$route.query.id,
            type: "profile",
            /**
             * @param {boolean} isCustomToRolePage
             * 额外添加的参数
             * 只有在简档主页是从自定义页面跳转到简档主页进行编辑时
             * 才设置此值为true（此值才有用，否则此值没有用）
             */
            isCustomToRolePage: true,
          };
          this.getDashboardSnapshot(this.getLayoutArr(data.data));
          // 组件超出设定的50格最大高度，发出警告
          let flag = true;
          data.data.forEach((item) => {
            if (item.y > 50 && flag) {
              // "警告！添加组件后高度超出导致显示不全，请删除部分组件。"
              this.$message.error(
                this.$i18n.t("vue_label_homepage_somecomponents")
              );
              flag = false;
            }
          });
          resolve(true);
        } catch (error) {
          reject(false);
        }
      });
    },
    /**
     * 获取报表快照
     * @param {Array} layouts 图表列表数据
     */
    async getDashboardSnapshot(layouts) {
      const ids = layouts.reduce(
        (prev, next) =>
          prev +
          (prev ? "," : "") +
          (next.type === "homepageChart" ? next.relatedid : next.i),
        ""
      );
      let withoutSnapshotCharts = [];
      try {
        const {
          data: { chartInfoList },
        } = await request.getDashboardSnapshot(ids);
        const chartIds = ids.split(",");
        //遍历图表数据，渲染图表
        chartInfoList.forEach((_) => {
          // index === 0 && withoutSnapshotCharts.push(_.chartId)
          this.$bus.$emit("chart_render", _.chartId, _);
        });
        //过滤没有快照的图表
        for (let i = 0; i < chartIds.length; i++) {
          // if(i === 0 ){
          //   continue
          // }
          const idTemp = chartIds[i];
          if (!chartInfoList.find((_) => _.chartId === idTemp)) {
            withoutSnapshotCharts.push(idTemp);
          }
        }
      } catch (err) {
        withoutSnapshotCharts = ids.split(",");
      } finally {
        //TODO:三个并发刷新图表
        while (withoutSnapshotCharts.length > 0) {
          this.$bus.$emit("chart_refresh_self", withoutSnapshotCharts.shift());
        }
      }
    },
    // 获取主页布局数据(正常情况,没有id请求此接口)
    async getHomePageLayoutData(pageType) {
      // 1,先获取id
      const {
        HomePageListData,
        homePageObjectData,
        rolePageDropDownNameObject,
      } = await request.getHomePageListToCurrentPageObjectData(pageType);
      // 传值给标准主页
      this.rolePageDropDownNameS = HomePageListData
        ? HomePageListData[0].name
        : "";
      this.HomePageListData = HomePageListData;
      if (homePageObjectData.name) {
        document.title = this.$setTitle(homePageObjectData.name);
        this.$store.commit(types.SET_TAB_NAME, homePageObjectData.name);
      }

      //2, 获取主页组件布局
      let { data } = await request.getHomePageLayout({
        id: homePageObjectData.id,
        iseditLayout: "false", // 表示不是走的编辑自定义主页，这时候需要记录当前主页
      });
      this.commonDataArr = data.data || [];
      this.rolePageDropDownName = rolePageDropDownNameObject
        ? rolePageDropDownNameObject.name
        : "";

      // 页面的参数对象
      this.homePageObjectData = {
        homePageName: data.homePageName,
        id: homePageObjectData.id,
        type: "profile",
        /**
         * @param {boolean} isCustomToRolePage
         * 额外添加的参数
         * 只有在简档主页是从自定义页面跳转到简档主页进行编辑时
         * 才设置此值为true（此值才有用，否则此值没有用）
         */
        isCustomToRolePage: false,
      };
      this.getDashboardSnapshot(this.getLayoutArr(data.data));
      // 组件超出设定的50格最大高度，发出警告
      let flag = true;
      data.data.forEach((item) => {
        if (item.y > 50 && flag) {
          // "警告！添加组件后高度超出导致显示不全，请删除部分组件。"
          this.$message.error(
            this.$i18n.t("vue_label_homepage_somecomponents")
          );
          flag = false;
        }
      });
    },

    /**
     * 从自定义主页进来时的简档主页头部发射事件部分
     */
    // 退出
    rolePageHeaderCardExitEvent() {
      this.isEditStatus = false;
      this.$router.push("/homePageObject/customPage");
    },
    // 删除当前简档主页
    async rolePageHeaderCardDeleteEvent() {
      await request.deleteHomePage({
        id: this.$route.query.id,
      });
      // 删除页面成功
      this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
      this.$router.push("/homePageObject/customPage");
    },
    // 重新排序
    rolePageHeaderCardReSortEvent() {
      this.isEditStatus = true;
    },
    // 添加组件
    rolePageHeaderCardAddCpnEvent() {
      // 添加组件事件
      this.isShowComPersonAddAssembly = true;
    },
    // 管理员权限的header修改简档主页名称事件
    HeaderBarEditRolePageNameEvent() {},
    // 取消编辑状态
    cancelEditStatus() {
      this.getHomePageLayout();
      this.isEditStatus = false;
    },

    // 编辑主页名称保存
    async saveRolePageNameEvent(headerBarEditToSaveName) {
      const { data } = await request.saveHomePage({
        id: this.$route.query.id,
        name: headerBarEditToSaveName,
        type: "profile",
      });
      // 修改简档主页名称成功
      this.$message.success(this.$i18n.t("message.modify.success"));
      this.homePageObjectData.homePageName = data.name;
    },
    /**
     * 添加组件弹框
     */
    // 取消添加组件
    assemblyCancel() {
      this.isShowComPersonAddAssembly = false;
    },
    // 保存添加卡片视图组件
    async assemblySave(val) {
      let tmpAuthority = {
        isEdit: null,
        isDelete: null,
        isNewTask: null,
        isNewEvent: null,
      };
      // 拼接选择按钮操作项菜单数据
      val.CardChoosebtn.forEach((item) => {
        if (item.isEdit === "true") {
          tmpAuthority.isEdit = "true";
        } else if (item.isDelete === "true") {
          tmpAuthority.isDelete = "true";
        } else if (item.isNewTask === "true") {
          tmpAuthority.isNewTask = "true";
        } else if (item.isNewEvent === "true") {
          tmpAuthority.isNewEvent = "true";
        }
      });

      // 获取组件的位置信息（封装的工具）
      let cpnLocation = getCpnLocation(this.locationLayoutVal);
      // 组装保存卡片视图对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          i: val.i,
          name: val.CardName,
          type: "CardView",
          iscustom: "1", //是否自定义
          objid: val.CardObject, //对象id
          relatedid: val.CardView, //视图id
          fieldids: val.CardField, //要显示的字段id（用，分割）
          h: 4,
          w: 4,
          x: 0,
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minH: 4,
          minW: 4,
          ...tmpAuthority,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });

      // ------------------------------------------------------------------------------
      /**
       * 主页控制组件上限（15个），超出时全局提示，无法添加，看板算1个组件
       * 别的添加组件的方法都要加上此限制，（把此部分复制粘贴即可）
       */
      if (this.locationLayoutVal.length >= 15) {
        // 单个页面最多添加15个组件
        this.$message.warning(
          this.$i18n.t("label.componenteditor.Limit.number.components")
        );
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存卡片视图成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayout();

      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },
    // 编辑卡片视图组件保存方法
    async cardCpnEditSave(val) {
      let tmpAuthority = {
        isEdit: null,
        isDelete: null,
        isNewTask: null,
        isNewEvent: null,
      };
      // 拼接选择按钮操作项菜单数据
      val.CardChoosebtn.forEach((item) => {
        if (item.isEdit === "true") {
          tmpAuthority.isEdit = "true";
        } else if (item.isDelete === "true") {
          tmpAuthority.isDelete = "true";
        } else if (item.isNewTask === "true") {
          tmpAuthority.isNewTask = "true";
        } else if (item.isNewEvent === "true") {
          tmpAuthority.isNewEvent = "true";
        }
      });

      // 组装保存卡片视图对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          i: val.i,
          name: val.CardName,
          type: "CardView",
          iscustom: "1", //是否自定义
          objid: val.CardObject, //对象id
          relatedid: val.CardView, //视图id
          fieldids: val.CardField, //要显示的字段id（用，分割）
          h: this.editCpnSelectCpnObject.h,
          w: this.editCpnSelectCpnObject.w,
          x: this.editCpnSelectCpnObject.x,
          y: this.editCpnSelectCpnObject.y,
          minH: this.editCpnSelectCpnObject.minH,
          minW: this.editCpnSelectCpnObject.minW,
          ...tmpAuthority,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存卡片视图成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      this.getHomePageLayout();
    },
    // 保存固定组件请求
    async fixedComponentdDataSave(val) {
      // 固定组件需要的数据
      let fixedComponentdObject = {};
      // 卡片视图的操作权限：编辑、删除、新建任务、新建事件
      let tmpAuthority = {
        isEdit: null,
        isDelete: null,
        isNewTask: null,
        isNewEvent: null,
      };
      val.map((item) => {
        fixedComponentdObject.fixedName = item.name;
        fixedComponentdObject.fixedType = item.type;
        fixedComponentdObject.fixedWhile = item.w;
        fixedComponentdObject.fixedHeight = item.h;
        fixedComponentdObject.minH = item.minH;
        fixedComponentdObject.minW = item.minW;
        fixedComponentdObject.fixedRelatedid = item.relatedid;
        tmpAuthority.isEdit = item.isEdit;
        tmpAuthority.isDelete = item.isDelete;
        tmpAuthority.isNewTask = item.isNewTask;
        tmpAuthority.isNewEvent = item.isNewEvent;
      });

      // 获取组件的位置信息（封装的工具）
      // 传值传递监听的参数，因为传递原始的布局数组会导致页面参数不是最新的
      let cpnLocation = getCpnLocation(this.locationLayoutVal);
      // 组装保存卡片视图对象
      let components = [];
      components = [
        {
          i: val.i, //添加卡片视图是不需要传id，编辑时才要传值
          name: fixedComponentdObject.fixedName,
          type: fixedComponentdObject.fixedType,
          relatedid: fixedComponentdObject.fixedRelatedid,
          iscustom: "0", //是否自定义
          h: fixedComponentdObject.fixedHeight,
          w: fixedComponentdObject.fixedWhile,
          x: 0,
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minH: fixedComponentdObject.minH,
          minW: fixedComponentdObject.minW,
        },
      ];
      // 如果固定组件类型是卡片视图，传参需要添加对应权限入参
      if (fixedComponentdObject.fixedType === "CardView") {
        components = [
          {
            i: val.i, //添加卡片视图是不需要传id，编辑时才要传值
            name: fixedComponentdObject.fixedName,
            type: fixedComponentdObject.fixedType,
            relatedid: fixedComponentdObject.fixedRelatedid,
            iscustom: "0", //是否自定义
            h: fixedComponentdObject.fixedHeight,
            w: fixedComponentdObject.fixedWhile,
            x: 0,
            y: cpnLocation.maxY + cpnLocation.confirmH,
            minH: fixedComponentdObject.minH,
            minW: fixedComponentdObject.minW,
            ...tmpAuthority,
          },
        ];
      }
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });

      if (this.locationLayoutVal.length >= 15) {
        // 单个页面最多添加15个组件
        this.$message.warning(
          this.$i18n.t("label.componenteditor.Limit.number.components")
        );
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭新增组件弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存固定组件成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayout();
      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },

    //编辑保存自定义组件
    // async customComponentdEdit() {
    //   //
    //   let components = [
    //     {
    //       // 自定义组件需要的id
    //       // i: "Preview",
    //       name: "自定义组件&myPage&" + sessionStorage.getItem("pageapi"),
    //       type: "Preview",
    //       relatedid: "Preview",
    //       iscustom: "0", //是否自定义
    //       h: "6",
    //       w: "8",
    //       x: "0",
    //       y: "0",
    //       minH: "1",
    //       minW: "1",
    //     },
    //   ];
    //   let res = await request.saveHomePageComponentsLayout({
    //     homePageId: this.homePageObjectData.id,
    //     components: JSON.stringify(components),
    //   });
    //   await this.getHomePageLayout();
    //   //在添加完组件之后定位到添加的组件位置
    //   this.$bus.$emit("scrollPositionBus");
    // },

    //编辑保存自定义组件
    async customComponentdEdit() {
      //
      let components = [
        {
          // 自定义组件需要的id
          // i: "Preview",
          name: "自定义组件&myPage&" + sessionStorage.getItem("pageapi"),
          type: "Preview",
          relatedid: "Preview",
          iscustom: "0", //是否自定义
          h: "6",
          w: "8",
          x: "0",
          y: "0",
          minH: "1",
          minW: "1",
        },
      ];
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      await this.getHomePageLayout();
      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },

    // 编辑保存固定组件请求
    async editPinnedComponents(val) {
      let fixedComponentdObject = {};
      // 卡片视图的操作权限：编辑、删除、新建任务、新建事件
      let tmpAuthority = {
        isEdit: null,
        isDelete: null,
        isNewTask: null,
        isNewEvent: null,
      };
      val.map((item) => {
        fixedComponentdObject.fixedName = item.name;
        fixedComponentdObject.fixedType = item.type;
        fixedComponentdObject.fixedWhile = item.w;
        fixedComponentdObject.fixedHeight = item.h;
        fixedComponentdObject.minH = item.minH;
        fixedComponentdObject.minW = item.minW;
        fixedComponentdObject.fixedRelatedid = item.relatedid;
        tmpAuthority.isEdit = item.isEdit;
        tmpAuthority.isDelete = item.isDelete;
        tmpAuthority.isNewTask = item.isNewTask;
        tmpAuthority.isNewEvent = item.isNewEvent;
      });

      let cpnLocation = getCpnLocation(this.locationLayoutVal);
      // 组装保存固定对象
      let components = [];
      components = [
        {
          // 编辑固定组件需要的id
          i: this.idCpn,
          name: fixedComponentdObject.fixedName,
          type: fixedComponentdObject.fixedType,
          relatedid: fixedComponentdObject.fixedRelatedid,
          iscustom: "0", //是否自定义
          h: fixedComponentdObject.fixedHeight,
          w: fixedComponentdObject.fixedWhile,
          x: 0,
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minH: fixedComponentdObject.minH,
          minW: fixedComponentdObject.minW,
        },
      ];
      // 如果固定组件类型是卡片视图，传参需要添加对应权限入参
      if (fixedComponentdObject.fixedType === "CardView") {
        components = [
          {
            // 编辑固定组件需要的id
            i: this.idCpn,
            name: fixedComponentdObject.fixedName,
            type: fixedComponentdObject.fixedType,
            relatedid: fixedComponentdObject.fixedRelatedid,
            iscustom: "0", //是否自定义
            h: fixedComponentdObject.fixedHeight,
            w: fixedComponentdObject.fixedWhile,
            x: 0,
            y: cpnLocation.maxY + cpnLocation.confirmH,
            minH: fixedComponentdObject.minH,
            minW: fixedComponentdObject.minW,
            ...tmpAuthority,
          },
        ];
      }
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭新增组件弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存固定组件成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      this.getHomePageLayout();
    },

    // 根据图表类型确定图表的最小宽高设置
    getMinWH(dashboardtype) {
      let minW = 3;
      let minH = 3;
      switch (dashboardtype) {
        case "heatMap": // 热力图，4*4
          minW = 4;
          minH = 4;
          break;
        case "number": // 数字图表，2*2
          minW = 2;
          minH = 2;
          break;
        case "digital": // 同环比数字图表，4*3
          minW = 4;
          minH = 2;
          break;
        case "bar_0": // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case "bar_1": // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case "bar_duidie": // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case "column_0": // 没有第二个分组的柱状图，4*
          minW = 4;
          minH = 3;
          break;
        case "column_1": // 二维柱形图，4*4
          minW = 4;
          minH = 3;
          break;
        case "column_duidie": // 6柱状堆叠图，4*4
          minW = 4;
          minH = 3;
          break;
        case "line_0": // 没有第二个分组的折线图，4*4
          minW = 4;
          minH = 3;
          break;
        case "line_1": // 有第二个分组的折线图，4*4
          minW = 4;
          minH = 3;
          break;
        case "biaxial": // 同环比双轴图，4*4
          minW = 4;
          minH = 4;
          break;
        case "waterfall": // 瀑布图*4
          minW = 4;
          minH = 3;
          break;
        case "area": // 面积图*4
          minW = 4;
          minH = 3;
          break;
        case "biaxialFigure": // 双轴图*4
          minW = 4;
          minH = 4;
          break;
        default: // 其他，3*3
          minW = 3;
          minH = 3;
          break;
      }
      return {
        minW: minW,
        minH: minH,
      };
    },
    // 添加单个仪表板组件保存请求
    async saveDashboardCpn(val) {
      let cpnLocation = getCpnLocation(this.commonDataArr);
      // 组装保存仪表板对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          // i: val.i,
          name: val.name,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          // type: val.DashboardassemblyID ? "Dashboard" : "PersonalPageCpn",
          type: val.type
            ? val.type
            : val.DashboardassemblyID
            ? "Dashboard"
            : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板页面id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          h: val.DashboardassemblyID ? 4 : "",
          w: val.DashboardassemblyID ? 4 : "",
          x: 0,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minW: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minW
            : 8,
          minH: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minH
            : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      // ------------------------------------------------------------------------------
      /**
       * 主页控制组件上限（15个），超出时全局提示，无法添加，看板算1个组件
       * 别的添加组件的方法都要加上此限制，（把此部分复制粘贴即可）
       */
      if (this.locationLayoutVal.length >= 15) {
        // // 单个页面最多添加15个组件
        this.$message.warning(
          this.$i18n.t("label.componenteditor.Limit.number.components")
        );
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });

      //
      //       return
      this.isShowComPersonAddAssembly = false;
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayout();
      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },
    // 编辑单个仪表板组件保存请求
    async assemblyDashboardEditSave(val) {
      // 组装保存卡片视图对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          i: val.i,
          name: val.name,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          // type: val.DashboardassemblyID ? "Dashboard" : "PersonalPageCpn",
          type: val.type
            ? val.type
            : val.DashboardassemblyID
            ? "Dashboard"
            : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板页面id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          // h: this.editCpnSelectCpnObject.h,
          // w: this.editCpnSelectCpnObject.w,
          // x: this.editCpnSelectCpnObject.x,
          // y: this.editCpnSelectCpnObject.y,
          h:
            this.editCpnSelectCpnObject.h !== undefined
              ? this.editCpnSelectCpnObject.h
              : val.h,
          w:
            this.editCpnSelectCpnObject.w !== undefined
              ? this.editCpnSelectCpnObject.w
              : val.w,
          x:
            this.editCpnSelectCpnObject.x !== undefined
              ? this.editCpnSelectCpnObject.x
              : val.x,
          y:
            this.editCpnSelectCpnObject.y !== undefined
              ? this.editCpnSelectCpnObject.y
              : val.y,
          minW: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minW
            : 8,
          minH: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minH
            : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      this.isShowComPersonAddAssembly = false;
      // 保存仪表板成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      this.getHomePageLayout();

      /**
       * 让仪表板当前要更新的组件仪表请求新数据，达到单个组件编辑更新
       * 如果 flag为true，则编辑保存仪表板单个组件时可以单个更新单个组件
       * 在仪表板组件中的超全局事件监听 getLinkagegetData 中有监听
       * DashboardassemblyID 是编辑选中后的id
       */
      this.$bus.$emit("getLinkagegetData", {
        id: val.DashboardassemblyID,
      });
    },

    // 添加仪表盘页面保存的请求方法
    async instrumentClusterSave(val) {
      let cpnLocation = getCpnLocation(this.commonDataArr);
      // 组装保存卡片视图对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          // i: val.i,
          name: val.name,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          type: val.DashboardassemblyID ? "Dashboard" : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板页面id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          h: val.DashboardassemblyID ? 3 : "",
          w: val.DashboardassemblyID ? 4 : "",
          x: 0,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minW: val.DashboardassemblyID ? 3 : 8,
          minH: val.DashboardassemblyID ? 4 : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });

      // ------------------------------------------------------------------------------
      /**
       * 主页控制组件上限（15个），超出时全局提示，无法添加，看板算1个组件
       * 别的添加组件的方法都要加上此限制，（把此部分复制粘贴即可）
       */
      if (this.locationLayoutVal.length >= 15) {
        // 单个页面最多添加15个组件
        this.$message.warning(
          this.$i18n.t("label.componenteditor.Limit.number.components")
        );
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------

      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      this.isShowComPersonAddAssembly = false;
      // 保存仪表盘页面成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayout();
      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },

    // 编辑仪表盘页面保存的方法
    async dashboardPageSave(val) {
      // 组装保存仪表板对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          i: val.i,
          name: val.name,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          type: val.DashboardassemblyID ? "Dashboard" : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板页面id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          // h: this.editCpnSelectCpnObject.h,
          h: "",
          w: this.editCpnSelectCpnObject.w,
          x: this.editCpnSelectCpnObject.x,
          y: this.editCpnSelectCpnObject.y,
          minW: val.DashboardassemblyID ? 3 : 8,
          minH: val.DashboardassemblyID ? 4 : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      this.isShowComPersonAddAssembly = false;
      // 保存仪表板成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      this.getHomePageLayout();
    },
  },
};
</script>
<style scoped lang="scss">
.common_index_div {
  // margin-top: 60px;
}
.body {
  padding: 10px;
}
</style>
